import { Card, Typography, Toolbar, Divider, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { ProjectPropertiesState } from "..";
import { ProjectPropertiesViewProps } from "../View";
import { Field } from "react-final-form";
import TextFieldControl from "src/components/form-elements/TextFieldControl";
import LabelCell from "src/components/LabelCell";

export const CloudProviders = {
  gcp: "gcp",
  aws: "aws",
};

const RetentionRules = ({ classes }: ProjectPropertiesViewProps & ProjectPropertiesState) => {
  return (
    <div>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Toolbar>
          <Typography variant="subtitle1">Retention Rules</Typography>
        </Toolbar>
        <Divider />
        <Table>
          <TableBody>
            {/* <TableRow>
              <TableCell style={{ width: 220 }}>
                <LabelCell
                  label="GCS/AWS Retention (days)"
                  tooltip="The number of days to retain data in GCS/AWS storages before it is deleted. We keep there raw dump files and processed data."
                />
              </TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="retentionRules.gcs_aws" />
              </TableCell>
            </TableRow> */}

            <TableRow>
              <TableCell style={{ width: 220 }}>
                <LabelCell
                  label="Influx Retention (days)"
                  tooltip="The number of days to retain data in Influx database. To change this value, please go to WatchRTC settings and look for Retention Period setting."
                />
              </TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="watchrtc_influxdb_retention" disabled />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ width: 220 }}>
                <LabelCell label="WatchRTC Retention (days)" tooltip="The number of days to retain WatchRTC data" />
              </TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="retentionRules.watchRTC" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ width: 220 }}>
                <LabelCell label="ProbeRTC Retention (days)" tooltip="The number of days to retain ProbeRTC data" />
              </TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="retentionRules.probeRTC" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ width: 220 }}>
                <LabelCell label="QualityRTC Retention (days)" tooltip="The number of days to retain QualityRTC data" />
              </TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="retentionRules.qualityRTC" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ width: 220 }}>
                <LabelCell label="TestRTC Retention (days)" tooltip="The number of days to retain TestRTC data" />
              </TableCell>
              <TableCell>
                <Field component={TextFieldControl} type="number" name="retentionRules.testRTC" />
              </TableCell>
            </TableRow>

            {/* <TableRow>
              <TableCell style={{ width: 220 }}>
                <LabelCell label="Region" tooltip="The AWS region where the data is stored" />
              </TableCell>
              <TableCell>
                <Field component={TextFieldControl} name="retentionRules.region" />
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default RetentionRules;
