import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { FormApi } from "final-form";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Field } from "react-final-form";
import {
  Button,
  Card,
  Divider,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { CheckboxControl, SelectFieldControl, TextFieldControl } from "../../../../components/form-elements";
import { beautifier } from "../../../../helpers/jsonBeautifyHelper";
import { ProjectPropertiesViewProps } from "../View";
import { ProjectPropertiesState } from "../index";
import {
  InsightConfigRule,
  InsightsProducts,
  analyzeRTCInsightSeverity,
  qualityRTCInsightSeverity,
} from "../constants/insightsConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: "100%",
      marginTop: theme.spacing(3),
      paddingBottom: 20,
      padding: 0,
    },
  })
);

interface InsightsCustomisationsProps extends ProjectPropertiesViewProps {
  form: FormApi;
  config: ProjectPropertiesState["default_insights_config"];
  initial: ProjectPropertiesState["initialValues"]["insights_customisations"];
  product: InsightsProducts;
}

const InsightsApplicableProducts = [InsightsProducts.qualityRTC, InsightsProducts.analyzeRTC];

const InsightsCustomisations = ({ form, config, initial, product }: InsightsCustomisationsProps) => {
  const classes = useStyles();

  useEffect(() => {
    if (!form.getRegisteredFields().includes("insights_customisations")) {
      form.registerField("insights_customisations", (a) => a.value, { value: true });
    }
  }, [form]);

  useEffect(() => {
    if (config && initial) {
      const result = {
        [InsightsProducts.qualityRTC]: [] as InsightConfigRule[],
        [InsightsProducts.analyzeRTC]: [] as InsightConfigRule[],
      };

      InsightsApplicableProducts.forEach((product) => {
        const values = new Map(initial[product].map((item) => [item.id, item]));

        config[product].forEach((item) => {
          const value = values.get(item.id);
          const rule = value
            ? {
                ...value,
                id: Number(item.id),
                enabled: item.enabled ? value.enabled : false,
                message: beautifier(JSON.stringify(value.message)),
                threshold: value.threshold ? beautifier(JSON.stringify(value.threshold)) : undefined,
              }
            : {
                ...item,
                message: beautifier(JSON.stringify(item.message)),
                threshold: item.threshold ? beautifier(JSON.stringify(item.threshold)) : undefined,
              };

          rule.message = rule.message.replace(/^"|"$/g, ""); // Remove leading/trailing quotes
          result[product].push(rule);
        });
      });

      form.change("insights_customisations", result);
    }
  }, [config, initial]);

  // Memoized form fields generator
  const getFormFields = useCallback(() => {
    const state = form.getFieldState("insights_customisations");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const values = useMemo(() => (state?.value[product] || []) as InsightConfigRule[], [state, product]);

    const handleReset = (index: number) => {
      if (config[product][index]) {
        const defaultValue = config[product][index];
        form.change(`insights_customisations[${product}].[${index}]`, {
          ...defaultValue,
          message: typeof defaultValue.message !== "string" ? beautifier(defaultValue.message) : defaultValue.message,
          threshold: defaultValue.threshold
            ? typeof defaultValue.threshold !== "string"
              ? beautifier(defaultValue.threshold)
              : defaultValue.threshold
            : undefined,
        });
      }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isDisabled = useCallback((index: number) => !config[product]?.[index]?.enabled, [config, product]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isChanged = useCallback(
      (index: number) => {
        const value = values[index];
        if (!config[product][index] || !value) {
          return false;
        }

        try {
          const parsedMessage = typeof value.message !== "string" ? value.message : JSON.parse(value.message);
          const parsedThreshold = value.threshold
            ? typeof value.threshold !== "string"
              ? value.threshold
              : JSON.parse(value.threshold)
            : undefined;
          const current = { ...value, message: parsedMessage, threshold: parsedThreshold };
          return JSON.stringify(current) === JSON.stringify(config[product][index]);
        } catch {
          return JSON.stringify(value) === JSON.stringify(config[product][index]);
        }
      },
      [values, config, product]
    );

    return values.map((item, index) => (
      <TableRow key={item.id}>
        <TableCell>
          <Field
            component={CheckboxControl}
            type="checkbox"
            name={`insights_customisations[${product}].[${index}].enabled`}
            disabled={isDisabled(index)}
          />
        </TableCell>
        <TableCell>
          <span style={{ display: "block", padding: "6px 0", fontWeight: "bold" }}>{item.id}</span>
          <Typography variant="body2">{item.category}</Typography>
        </TableCell>
        <TableCell>
          <Field
            component={SelectFieldControl}
            name={`insights_customisations[${product}].[${index}].severity`}
            options={
              `${product}` === InsightsProducts.analyzeRTC ? analyzeRTCInsightSeverity : qualityRTCInsightSeverity
            }
            disabled={isDisabled(index)}
          />
        </TableCell>
        <TableCell>
          <Field
            component={TextFieldControl}
            name={`insights_customisations[${product}].[${index}].message`}
            multiline
            rows={5}
            disabled={isDisabled(index)}
          />
          <Field
            component={TextFieldControl}
            name={`insights_customisations[${product}].[${index}].link`}
            disabled={isDisabled(index)}
          />
        </TableCell>
        <TableCell>
          {item.threshold && (
            <Field
              component={TextFieldControl}
              name={`insights_customisations[${product}].[${index}].threshold`}
              multiline
              rows={5}
              disabled={isDisabled(index)}
            />
          )}
        </TableCell>
        <TableCell>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleReset(index)}
            disabled={isChanged(index) || isDisabled(index)}
          >
            Reset
          </Button>
        </TableCell>
      </TableRow>
    ));
  }, [form, config]);

  if (!config) {
    return (
      <Card className={classes.card}>
        <Toolbar>
          <Typography variant="subtitle1">Insights Customisation...</Typography>
        </Toolbar>
        <Divider />
        <Skeleton height="400px" />
      </Card>
    );
  }

  return (
    <Card className={classes.card}>
      <Toolbar>
        <Typography variant="subtitle1">{product} Insights Customisation</Typography>
      </Toolbar>
      <Divider />

      <CustomTable>
        <TableHead>
          <TableRow>
            <TableCell>Enabled</TableCell>
            <TableCell>Rule ID | Category</TableCell>
            <TableCell>Severity</TableCell>
            <TableCell>Message | Link</TableCell>
            <TableCell>Thresholds</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{getFormFields()}</TableBody>
      </CustomTable>
    </Card>
  );
};

const CustomTable = styled(Table)`
  height: 720px;
  overflow: auto;
  display: block;

  & .MuiTableHead-root,
  & .MuiTableBody-root {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  & .MuiTableHead-root {
    position: sticky;
    top: 0;
    display: table;
    background: white;
    z-index: 10;
  }

  & .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    margin-left: 0;
    margin-right: 0;
    margin-top: -6px;
  }

  & tr {
    td,
    th {
      padding: 6px;

      &:first-of-type {
        padding-left: 24px;
        width: 80px;
      }

      &:last-of-type {
        padding-right: 12px;
      }

      &:nth-of-type(2) {
        width: 160px;
      }

      &:nth-of-type(3) {
        width: 160px;
      }

      &:nth-of-type(4) {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      &:nth-of-type(5) {
        width: 220px;
      }

      &:last-of-type {
        width: 82px;
      }
    }

    td input {
      font-size: 0.8125rem;
      padding-bottom: 8px;
    }

    td textarea {
      font-size: 0.8125rem;
      padding: 0 6px !important;
    }

    td {
      vertical-align: top;
    }

    td:nth-of-type(2) {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    }
  }
`;

export default memo(InsightsCustomisations);
