import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useChartSize } from "./useChartSize";
import { chartSizesArray, chartSizesLabels } from "./constants";
import { IconButton } from "@mui/material";
import { DensityLargeOutlined, DensityMediumOutlined, DensitySmallOutlined } from "@mui/icons-material";

interface IChartSizeProps {
  defaultSize: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginRight: theme.spacing(2),
    },
    buttonWrap: {
      color: "rgb(119 119 119)",
      width: 80,
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      "& > svg": {
        width: 16,
      },
      "& > span": {
        width: 60,
      },
    },
  })
);

const ChartSize = (props: IChartSizeProps) => {
  const classes = useStyles();
  const { chartHeight, chartSize, setChartSize, resetChartsHeight } = useChartSize();

  const cIdx = chartSizesArray.indexOf(chartSize);
  const nextSize = chartSizesArray[cIdx < chartSizesArray.length - 1 ? cIdx + 1 : 0];

  const getChartSizeIcon = (size: number) => {
    switch (size) {
      case 1:
        return <DensityLargeOutlined />;
      case 0.8:
        return <DensityMediumOutlined />;
      case 0.6:
        return <DensitySmallOutlined />;
      default:
        return <DensityMediumOutlined />;
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setChartSize(props.defaultSize, true);
    }, 500);
  }, [props.defaultSize]);

  React.useEffect(() => {
    // chart is immutable, it will redraw when options get changed. need to resize chart at each render.
    resetChartsHeight(chartHeight, chartSize);
  });

  return (
    <div className={classes.container}>
      <Tooltip title={`Switch to ${chartSizesLabels[nextSize]}`} placement="top">
        <IconButton
          size="medium"
          aria-label="chart-size"
          onClick={() => {
            setChartSize(nextSize);
          }}
          sx={{
            color: (theme) => theme.palette.text.secondary,
            outline: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: "4px",
          }}
        >
          {getChartSizeIcon(chartSize)}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export * from "./constants";
export * from "./Context";
export * from "./useChartSize";
export default ChartSize;
