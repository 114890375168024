export const PRODUCT = {
  watchRTC: "watchRTC",
  watchRTCLive: "watchRTCLive",
  testingRTC: "testingRTC",
  upRTC: "upRTC",
  analyzeRTC: "analyzeRTC"
} as const;

export const TRACE_TYPE = {
  api: "api",
  app: "app",
  browser: "browser",
  keys: "keys",
} as const;
