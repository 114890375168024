import Tooltip from "@mui/material/Tooltip";
import { AccessTimeOutlined, TimerOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export type XAxisTimeline = "absolute" | "relative";

export interface IXAxisTimelineSwitchProps {
  xAxisTimeline: XAxisTimeline;
  setXAxisTimeline: (xAxisTimeline: XAxisTimeline) => void;
}

const XAxisTimelineSwitch = ({ xAxisTimeline, setXAxisTimeline }: IXAxisTimelineSwitchProps) => {
  const tooltip = xAxisTimeline === "absolute" ? `Switch to relative time` : `Switch to absolute time`;
  const switchTo = xAxisTimeline === "absolute" ? "relative" : "absolute";
  return (
    <Tooltip title={tooltip} placement="top">
      <IconButton
        size="medium"
        aria-label="x-axis-timeline"
        onClick={() => setXAxisTimeline(switchTo)}
        sx={{
          color: (theme) => theme.palette.text.secondary,
          outline: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: "4px",
          marginRight: (theme) => theme.spacing(2),
        }}
      >
        {xAxisTimeline === "absolute" ? <TimerOutlined /> : <AccessTimeOutlined />}
      </IconButton>
    </Tooltip>
  );
};

export default XAxisTimelineSwitch;
